import { IAlternatingSplashesCopy } from "../shared/AlternatingSplashes/AlternatingSplashes"
import Star from "./images/Star.svg"
import People from "./images/People.svg"
import Writing from "./images/Writing.svg"
import Key from "./images/Key.svg"
import Compass from "./images/Compass.svg"
import Eye from "./images/Eye.svg"
import ScheduleTherapist from "./images/schedule-therapist.webp"
import PhoneTherapy from "./images/phone-therapy.webp"
import { UilThumbsUp } from "@iconscout/react-unicons"
import { UilStar } from "@iconscout/react-unicons"
import { PlanInfo } from "../shared/ChooseService/ChooseService"

export const alternatingSplashCopy: IAlternatingSplashesCopy = {
  carePersonTitle: "therapist",
  topSection: {
    heading: "Quality therapists",
    image: ScheduleTherapist,
    body: [
      {
        heading: "Highly rated",
        description: "",
        icon: Star,
      },
      {
        heading: "Diverse backgrounds",
        description:
          "Our therapists have a wide range of life and professional experience to help you find someone you can truly connect with. 50% of our therapists come from minority backgrounds and many have LGBTQIA+ experience.",
        icon: People,
      },
      {
        heading: "Ongoing education",
        description:
          "Our therapists have access to subject matter experts and continuous learning programs to help them grow their skillsets and provide the most optimal care possible.",
        icon: Writing,
      },
    ],
  },
  bottomSection: {
    heading: "Approaches we use",
    image: PhoneTherapy,
    body: [
      {
        heading: "Cognitive behavioral therapy",
        description:
          "CBT helps identify specific challenges in your thought patterns and develop skills to respond to them more effectively. One of the most common and effective forms of talk therapy.",
        icon: Key,
      },
      {
        heading: "Dialectical behavioral therapy",
        description:
          "DBT focuses on self acceptance and understanding how two ideas that seem opposite can be true at the same time. Based on CBT and adapted for those with bipolar disorder, eating disorders, borderline personality disorder, suicidal ideation, and addiction.",
        icon: Compass,
      },
      {
        heading: "Eye movement desensitization and reprocessing",
        description:
          "EMDR is trauma-focused CBT that can help reduce the power of traumatic memories. A trained therapist guides you through thinking about a memory while moving your eyes back and forth to help your brain process them so they become less painful.",
        icon: Eye,
      },
    ],
  },
}

export const chooseServicePlans: Array<PlanInfo> = [
  {
    icon: UilThumbsUp,
    title: "Learn lifelong skills",
    planName: "Individual Therapy",
    planDescription:
      "45-minute video or phone sessions with a therapist. Includes initial evaluation and dedicated treatment plan.",
    weeklyPrice: 74,
    monthlyPrice: 295,
  },
  {
    icon: UilStar,
    title: "Bundle and save $30/mo",
    planName: "Therapy + Medication",
    planDescription:
      "45-minute therapy sessions with a therapist and monthly medication check-ins with a prescriber. Includes initial evaluation and dedicated treatment plans with both clinicians.",
    weeklyPrice: 92,
    monthlyPrice: 365,
  },
]
